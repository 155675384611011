<div mat-dialog-title class="flex items-center justify-between" >
  <div>Delete Confirmation </div>
  <button type="button" mat-icon-button (click)="onCancel()" tabindex="-1">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>
<mat-dialog-content *ngIf="user">
  <p>Are you sure you want to delete user {{ user.name }} {{ user.surname }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="cleanup">
  <p>Are you sure you want to delete cleanup with ID {{ cleanup.id }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="partner">
  <p>Are you sure you want to delete partner with ID {{ partner.id }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="fName">
  <p>Are you sure you want to delete partner logo with ID {{ fIndex }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="team">
  <p>Are you sure you want to delete team with ID {{ team.id }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="litter">
  <p>Are you sure you want to delete litter with ID {{ litter.id }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="litterCategory">
  <p>Are you sure you want to delete location type with ID {{ litterCategory.id }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="location">
  <p>Are you sure you want to delete location with ID {{ location.id }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="tip">
  <p>Are you sure you want to delete tip with ID {{ tip.id }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="game">
  <p>Are you sure you want to delete game with ID {{ game.id }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="teamId">
  <p>Are you sure you want to delete team with ID {{ teamId }}?</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="apc">
  <p>Are you sure you want to delete average plastic consumption with ID {{ apc.id }}?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button type="button" (click)="onCancel()">
    <i class="fa-solid fa-ban"></i>Cancel
  </button>
  <button mat-button color="primary" type="button" (click)="onConfirm()">
    <i mat-button class="fa-solid fa-check"></i>Delete
  </button>
</mat-dialog-actions>